import React from "react";

import BlockIcon from "@mui/icons-material/Block";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import { Box, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const AndroidIosSupport: React.FC<any> = () => {
  const delay = 200;
  const theme = useTheme();
  const text =
    "This feature is yet not supported on Android and iOS devices but will in the future.";

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            fontSize: "0.75rem",
            fontWeight: 400,
            padding: "12px 14px",
            boxShadow: "0 2px 3px rgba(0,0,0,.1)",
            border: theme.palette.divider,
            color: theme.palette.text.primary,
            bgcolor: theme.palette.background.paper,
          },
        },
      }}
      sx={{ cursor: "pointer" }}
      leaveDelay={delay}
      title={text}
      placement="top"
    >
      <div>
        <Box
          sx={{
            position: "relative",
            display: "inline-block",
            padding: "2px",
          }}
        >
          <AppleIcon sx={{ position: "relative", fontSize: 32 }} />
          <BlockIcon
            sx={{
              color: "red",
              position: "absolute",
              top: 1,
              left: 0,
              fontSize: 37,
            }}
          />
        </Box>

        <Box
          sx={{
            position: "relative",
            display: "inline-block",
            padding: "2px",
          }}
        >
          <AndroidIcon sx={{ position: "relative", fontSize: 32 }} />
          <BlockIcon
            sx={{
              color: "red",
              position: "absolute",
              top: 1,
              left: 0,
              fontSize: 37,
            }}
          />
        </Box>
      </div>
    </Tooltip>
  );
};

export default AndroidIosSupport;
